import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Box } from "../Core";
import { Link } from "gatsby";

const CopyRightArea = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  a {
    color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.darkShade} !important;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
    &:visited {
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
      color: ${({ dark, theme }) => dark ? theme.colors.accentYellow : theme.colors.secondary} !important;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
    dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ dark, theme }) => dark ? theme.colors.accentYellow : theme.colors.secondary} !important;
      }
    }
  }
`;

const Footer = ({ isDark = true }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "dark" : "light"}>
        <Container>
          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center">
              <Col sm="6" className="text-sm-left text-center mb-2 mb-sm-0">
                <p>&copy; 2024 <a href="https://sharptree.io" target="_blank" rel="noopener noreferrer">Sharptree LLC</a>, All Rights Reserved.</p>
              </Col>
              <Col sm="6" className="text-sm-right text-center">
                <Link to="/privacy">Privacy</Link>
                <span className="ml-5">
                  <Link to="/attributions">Attributions</Link>
                </span>
                <span className="ml-5">
                  <ul className="social-icons">
                    {/* <li>
                      <a href="https://twitter.com/sharptreellc" target="_blank" rel="noopener noreferrer">
                        <i className="icon icon-logo-twitter"></i>
                      </a>
                    </li> */}
                    <li>
                      <a href="https://www.linkedin.com/company/sharptree" target="_blank" rel="noopener noreferrer">
                        <i className="icon icon-logo-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </span>
              </Col>
            </Row>
          </CopyRightArea>
        </Container >
      </Box >
    </>
  );
};

export default Footer;
