import React from "react";
import { Link } from "gatsby";

import imgLogo from "../../assets/images/opqo_logo.png";

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      <img src={imgLogo} alt="" height="50" />
    </Link>
  );
};

export default Logo;
