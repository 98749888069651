import React from "react";
import styled from "styled-components";
import { color, space, typography, shadow } from "styled-system";

const EmailLink = styled.a`
  font-weight: 300;
  ${color};
  ${space};
  ${typography};
  ${shadow};
  &:link {
    text-decoration: underline;
  },
  &:hover,
  &:active,
  &.active {
    ${color};
  }
`;

const Email = ({ address = "#", ...props }) => {
  return <EmailLink href={`mailto:${address}`} color="primary" {...props}>{address}</EmailLink>;
};

export default Email;